<template>
    <div class="modal" :class="{'is-active': opened}">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Forecast Params</p>
          <button class="delete" @click="close"></button>
        </header>
        <section class="modal-card-body" v-if="product">
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Id Index</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input is-static" type="text" :value="product.idIndex" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Item</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input is-static" type="text" :value="product.nsName" readonly>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Days to stock</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="number" v-model.number="daysToStock">
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Factory Preparation Days</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <input class="input" type="number" v-model.number="factoryPrepDays">
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Optimized location ratio</label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
                <p class="control">
                  <a class="button is-static">CA</a>
                </p>
                <p class="control">
                  <input class="input cost-input" type="number" v-model.number="optimizedLocationRatio.CA">
                </p>
                <p class="control">
                  <a class="button is-static">NY</a>
                </p>
                <p class="control">
                  <input class="input cost-input" type="number" v-model.number="optimizedLocationRatio.NY">
                </p>
                <p class="control">
                  <a class="button is-static">TX</a>
                </p>
                <p class="control">
                  <input class="input cost-input" type="number" v-model.number="optimizedLocationRatio.TX">
                </p>
              </div>
            </div>
          </div>
  
          <div v-if="error" class="notification is-danger is-light">
            <button class="delete" @click="error=''"></button>
            {{error}}
          </div>
        </section>
        <footer class="modal-card-foot">
          <a class="button is-link" :class="{'is-loading': waiting}" :disabled="!isValid" @click="updateParams">Update</a>
          <a class="button" @click="close">Cancel</a>
        </footer>
      </div>
    </div>
  </template>
    
  <script>
  
  export default {
    name: 'forecast-params-modal',
    props: ['opened', 'product'],
    data () {
      return {
        error: '',
        waiting: false,
        daysToStock: 45,
        factoryPrepDays: 45,
        optimizedLocationRatio: {CA: 1, NY: 1, TX: 1},
      }
    },
    computed: {
      server () {
        return this.$store.state.config.server
      },
      isValid () {
        return this.daysToStock && this.optimizedLocationRatio.CA && this.optimizedLocationRatio.NY && this.optimizedLocationRatio.TX
          && this.factoryPrepDays
      },
    },
    watch: {
      product: function (val) {
        if (val) {
          var f = val.forecastParams
          this.daysToStock = f.daysToStock ? f.daysToStock : 45
          this.factoryPrepDays = f.factoryPrepDays ? f.factoryPrepDays : 45
          this.optimizedLocationRatio = f.optimizedLocationRatio ? f.optimizedLocationRatio : {CA: 1, NY: 1, TX: 1}
        }
      },
    },
    methods: {
      close () {
        this.$emit('closeForecastParamsModal')
      },
      updateParams () {
        if (this.waiting || !this.isValid) {
          return
        }
        this.waiting = true
        var message = {
          productId: this.product.id,
          daysToStock: this.daysToStock,
          factoryPrepDays: this.factoryPrepDays,
          optimizedLocationRatio: this.optimizedLocationRatio,
        }
        this.$http.post(this.server + '/myapp/update-forecast-params/', message).then(resp => {
          this.waiting = false
          this.$emit('closeForecastParamsModal', resp.body)
        }, err => {
          this.error = err.body
          this.waiting = false
        })
      },
    },
  }
  </script>
  
  <style scoped>
  </style>
  